<template>
  <div
      v-if="section.isHtml"
      v-html="section.text"
      class="htmlSection"
      :class="[{'py-2' : !noPadding}]"
      :style="!!fontSize ? `font-size: ${fontSize}px` : null"
      :id="'text_' + uuid"

  />
</template>

<script>

export default {
  name: "editorSectionHtml",
  props : {
    value : {
      type : Object
    },
    section : {
      type : Object,
      default() { return {} }
    },
    lang : {
      type : String,
      default : 'ua',
    },
    placeholder : {
      type : String
    },
    fontSize : {
      type : String,
    },
    noPadding : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      uuid : null
    }
  },
  mounted() {
    this.uuid = this.$uuid.v4()


    setTimeout(()=> {

      const domElements = document.querySelectorAll(`#${'text_' + this.uuid} a`);
      if (!domElements) {
        return
      }
      if (!Array.isArray(domElements)) {
        domElements.target = '_blank'
      }
      domElements.forEach(link => {
        link.target = '_blank'
      })


    },500)

    setTimeout(()=> {
      const paragraphs = document.querySelectorAll(`#${'text_' + this.uuid} p`);
      if (!paragraphs) {
        return
      }
      console.log('ARRAY' ,  paragraphs[paragraphs.length - 1] , paragraphs[paragraphs.length - 1].css)
      paragraphs[paragraphs.length - 1].style.marginBottom = '0'
    })





  }
}
</script>

<style scoped>
.htmlSection {
  font-size: 14px;
  font-weight: 400;
}
</style>