<template>

      <div v-if="section.isFile" >

        <div class="py-2" v-for="(file,i) in section.files"  :key="'file_' + i">
          <v-btn
              @click="openFile(file)"
              :color="wsACCENT"
              class="noCaps px-0 pr-2"
              text>
            <v-icon class="mr-3" :color="wsACCENT">mdi-paperclip</v-icon>
            {{ SHORTEN_FILE_NAME(file.name)  }}
          </v-btn>
        </div>

      </div>

</template>

<script>

import {mapActions } from "vuex";

export default {
  name: "editorSectionFile",
  props : {
    value : {
      type : Object
    },
    section : {
      type : Object,
      default() { return { files : [] } }
    },
    lang : {
      type : String,
      default : 'ua',
    },
    student : {
      type : Boolean,
      default : false,
    },
    courseId : {
      type : String,
    },
    isPublic : {
      type : Boolean,
      default : false,
    }
  },
  computed : {
  },
  methods : {
    ...mapActions('upload', [ 'GET_PRIVATE_FILE' , 'GET_PUBLIC_FILE', 'GET_STUDENT_COMPONENT_FILE' ]),

    fileAction( action, file , index ) {
      if ( action === 'open' ) {
        this.openFile(file)
      }
      if ( action === 'delete'  ) {
        this.section.files.splice(index,1)
        this.$emit('input',this.section)
      }

    },
    async openFile(file) {
      if ( this.student ) {
        return this.openStudentFile(file)
      }
      let result = this.isPublic ? await this.GET_PUBLIC_FILE(file.uuid) :  await this.GET_PRIVATE_FILE(file.uuid)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    async openStudentFile(file) {
      let data =  {
        file : file.uuid,
        course : this.courseId
      }

      let result = await this.GET_STUDENT_COMPONENT_FILE(data)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      a.remove();

    },

    addFile($event) {
      this.section.files.push($event)
      this.$emit('input', this.section)
    }
  },
  mounted() {
    if (this.value) {
      this.section = this.value
    }
  }
}
</script>

<style scoped>

</style>